
import { Options, Vue } from 'vue-class-component';
import {
    apiReportGetListTotalNum,
    apiUpdateCalStatus,
    apiReportGetList
} from '../services/tpsApi/tpsApiReport';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import {
    IReportGetListTotalNumRequestParams,
    IReportGetListRequestParams,
    IUpdateCalStatus
} from '../services/tpsApi/models/IReport';
import ReportSearchMenu from '@/components/ReportSearchMenu.vue';
import Table from '@/components/Table.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
    components: {
        ReportSearchMenu,
        Table,
        Pagination
    },
})
export default class Report extends Vue {
    TABLE_HEAD: {} = {
        "交易序號": "交易序號",
        "卡號": "卡號",
        "發卡公司": "發卡公司",
        "地區": "地區",
        "卡別": "卡別",
        "品牌名稱": "品牌名稱",
        "車號": "車號",
        "駕駛員帳號": "駕駛員帳號",
        "路線": "路線",
        "卡機號碼": "卡機號碼",
        "卡機內碼": "卡機內碼",
        "交易日期": "交易日期",
        "交易時間": "交易時間",
        "卡片交易序號": "卡片交易序號",
        "交易類別": "交易類別",
        "異常交易": "異常交易",
        "清分狀態": "清分狀態",
        "清分日期": "清分日期",
        "交易原價": "交易原價",
        "扣款金額": "扣款金額",
        "使用點數": "使用點數",
        "使用錢包": "使用錢包",
        "使用現金": "使用現金",
        "退款金額": "退款金額",
        "優惠模式":"優惠模式",
        "行動支付交易序號": "行動支付交易序號",
    };

    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;

    reportList: [] | Blob = [];
    REPORT = "1"; //report=1，表示產生第一種業者報表，交易明細表
    pageNo: {} = PAGENO;
    searchQuery: {} = {};

    mounted() {
        this.refreshTabel({ report: this.REPORT, limit: this.currentLimit, page: this.currentPage });
        this.initPagination();
    }

    async initPagination() {
        let query = Object.assign({ report: this.REPORT }, this.searchQuery);
        this.pageTotalNum = await apiReportGetListTotalNum(query);
        console.log(this.pageTotalNum);
    }

    async refreshTabel(pData: IReportGetListRequestParams) {
        if (pData && pData.limit) this.currentLimit = pData.limit;
        if (pData && pData.page) this.currentPage = pData.page;
        if (pData && !pData.report) pData.report = this.REPORT;
        pData = Object.assign(pData, this.searchQuery);
        this.reportList = await apiReportGetList(pData);
    }

    async download(pData: { output: string }) {
        const res = await apiReportGetList(Object.assign({
            report: this.REPORT,
            output: pData.output
        },
            this.searchQuery));
        exportFile({ fileName: "交易明細表", data: res });
    }

    doSearch(pData) {
        this.searchQuery = Object.assign({}, pData);
        this.initPagination();
        this.refreshTabel({ report: this.REPORT, limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE });
    }

    async updateCalStatus(item) {
        const res = await apiUpdateCalStatus(Object.assign({
            txn_date: item['交易日期'],
            txn_time: item['交易時間'],
            card_id: item['卡號'],
            reader_id: item['卡機內碼'],
            cal_status: 0
        }))
        this.refreshTabel({ report: this.REPORT, limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE });
    }
}
